/* eslint-disable max-len */
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

const alphabetizeCols = (cols, sortKey) => {
  cols.sort((a, b) => {
    const keyA = a[sortKey];
    const keyB = b[sortKey];

    if (keyA < keyB) {
      return -1;
    } if (keyA > keyB) {
      return 1;
    }
    return 0;
  });

  return cols;
};

function boolToString(obj) {
  // can't just do toString() b/c if that kv dne, we'll get an error
  if (obj) {
    return obj.toString();
  }
  return '';
}

/**
 * Structure the client data from the API to be used in the data grid table.
 * Sort Favorite clients into a separate array to be pinned to the grid.
 * @param {Array} data - The data received from the database of all clients
 * @param {Array} favorites - The list of favorite clients
 * @returns
 */
function createClientRows(data, favorites) {
  const formedRows = [];
  const pinnedRows = [];
  data[0].forEach((item) => {
    const current = item;
    // If the client is a favorite, add it to the pinnedRows array, otherwise add it to the formedRows array
    (favorites.includes(current.client_id) ? pinnedRows : formedRows).push({
      id: current.client_id,
      code: current.client_id,
      jiraname: current.jira_project_name,
      fullname: current.client_full_name,
      clienttype: current.client_type,
      adaimember: boolToString(current.is_advantage_member),
      actblueids: current.actblue_entity_ids,
      actionsdatasource: current.action_platform,
      adaisuppresseddomains: current.suppressed_domains,
      bingids: current.bing_account_ids,
      campaignmonitorlocation: current.campaign_monitor_location,
      classyorgid: current.classy_org_id,
      codedicurl: current.code_dictionary_url,
      curateautoupload: boolToString(current.curate_delivery_auto_upload),
      curatedays: current.curate_days,
      curatefrequency: current.curate_frequency,
      curatesubscriber: boolToString(current.curate_subscriber),
      donorfloortype: current.donor_floor_type,
      donorfloorvalue: current.donor_proportion_floor_value,
      emailexhaustdatasource: current.exhaust_platform,
      emailvalidationprovider: current.email_validation_provider,
      excludematchqualifier: current.exclude_from_match_qualifier,
      facebookids: current.facebook_account_ids,
      facebookloc: current.facebook_locations,
      featurize: boolToString(current.featurize),
      fiscalyearstart: current.fiscal_year_start,
      googleids: current.google_account_ids,
      goopid: current.goop_client_ids,
      hubspotstitchintegrationname: current.hubspot_stitch_integration_name,
      hubsrcpath: current.hub_source_path,
      inboxmonsterrule: current.inbox_monster_rule,
      isactive: boolToString(current.is_active),
      islowinventory: boolToString(current.is_low_inventory),
      ismidlevelmember: boolToString(current.is_mid_level_member),
      isstrategypartner: boolToString(current.is_strategy_partner),
      limitedmodeluniverse: boolToString(current.limited_model_universe),
      mailchimpdir: current.mailchimp_directory,
      mmclients: current.multi_member_clients,
      mmrequired: boolToString(current.multi_member_required),
      mmsmsclients: current.multi_member_sms_clients,
      ngpactbluemanualcodes: current.ngp_actblue_manual_codes,
      ngpeadb: current.ngp_everyaction_database_name,
      ngpeaserver: current.ngp_everyaction_server_name,
      notes: current.notes,
      outbrainids: current.outbrain_account_ids,
      pardotloc: current.pardot_location,
      pausedata: current.pause_data,
      peopledatasource: current.people_platform,
      salesforceloc: current.salesforce_location,
      salsakey: current.salsa_organization_key,
      sfgid: current.sfg_surveys_client_ids,
      sharecapmaxshares: current.share_cap_max_shares,
      sharecapmonths: current.share_cap_months,
      slackchannel: current.annelewis_slack_channel,
      smsdatasource: current.sms_platform,
      stwaccountids: current.stw_subaccount_ids,
      tatangoid: current.tatango_client_id,
      timezone: current.timezone,
      totemid: current.totem_client_ids,
      transactionsdatasource: current.transaction_platform,
      transactionsofflinedatasource: current.transactions_offline_platform,
      twitterids: current.twitter_account_ids,
      updatedat: current.updatedAt,
      createdat: current.createdAt,
    });
  });
  return { formedRows, pinnedRows };
}

const createCodeRows = (data) => {
  // eslint-disable-next-line array-callback-return
  const myrows = data[0].map((item) => {
    const current = item;
    return {
      id: current.id,
      client_id: current.client_id,
      code: current.code,
      source: current.source,
      audience: current.audience,
      audience_category: current.audience_category,
      revenue: current.revenue,
      revenue_category: current.revenue_category,
      type: current.type,
      fixed_cost_cpa_override: current.fixed_cost_cpa_override,
      fixed_cost_media_spend: current.fixed_cost_media_spend,
      most_recent_spend: current.most_recent_spend,
      most_recent_spend_date: current.most_recent_spend_date,
      platforms_with_spend: current.platforms_with_spend,
      total_spend: current.total_spend,
      transactions: current.transactions,
      most_recent_transaction_date: current.most_recent_transaction_date,
      from_goop: current.from_goop,
      createdAt: current.createdAt,
      page: current.page,
      actblue_managing_entities: current.actblue_managing_entities,
    };
  });
  return myrows;
};

const createSourceRows = (data) => {
  // eslint-disable-next-line array-callback-return
  const myrows = data[0].map((item) => {
    const current = item;
    return {
      channel: current.channel,
      channel_category: current.channel_category,
      source: current.source,
      client_id: current.client_id,
      id: current.id,
      from_goop: current.from_goop,
      createdAt: current.createdAt,
    };
  });
  return myrows;
};

/**
   * createTransactionRows - format data from api to use in data grid table
   * @param {Object} rowData The data received from the database
   * @returns {Array} The formatted rows ready for data grid table
   */
const createTransactionRows = (data) => {
  // eslint-disable-next-line array-callback-return
  const myrows = data[0].map((item) => {
    const current = item;
    return {
      amount: current.amount,
      client_id: current.client_id,
      code: current.code,
      code_source: current.code_source.items[0] ? current.code_source.items[0].source : null,
      createdAt: current.createdAt,
      email: current.email,
      exclude_flag: current.exclude_flag,
      exclusion_updated_dt: current.exclusion_updated_dt,
      foreign_client_key: current.foreign_client_key,
      foreign_platform: current.foreign_platform,
      foreign_transaction_key: current.foreign_transaction_key,
      id: current.id,
      mobile_flag: current.mobile_flag,
      recurrence: current.recurrence,
      recurring_period: current.recurring_period,
      recurring_type: current.recurring_type,
      refund_flag: current.refund_flag,
      transaction_dt: current.transaction_dt,
      updatedAt: current.updatedAt,
    };
  });
  return myrows;
};

/**
   * createInsightsRows - format data from api to use in data grid table
   * @param {Object} rowData The data received from the database
   * @param {Boolean} isCohort If the table is a cohort table (it has extra columns)
   * @param {String} tableName optional table name to handle special column for reac cohort table
   * @returns {Array} The formatted rows ready for data grid table
   */
const createInsightsRows = (data, isCohort, tableName) => {
  const myrows = data[0].map((item) => {
    const current = item;
    const formatted = {
      id: current.id,
      Break_even_date: current.Break_even_date,
      Days_to_break_even: current.Days_to_break_even,
      Projected_break_even_date: current.Projected_break_even_date,
      Projected_days_to_break_even: current.Projected_days_to_break_even,
      ROAS: current.ROAS,
      ROAS_Over_Days: current.ROAS_Over_Days,
      Unsubs: current.Unsubs,
      average_gift: current.average_gift,
      category: current.category,
      click_emails: current.click_emails,
      click_rate: current.click_rate,
      clicks: current.clicks,
      client: current.client,
      client_type: current.client_type,
      contributions: current.contributions,
      contributions_over_sends: current.contributions_over_sends,
      donors: current.donors,
      emails_acquired: current.emails_acquired,
      emails_messaged: current.emails_messaged,
      first_delivery_date: current.first_delivery_date, // aws date time
      first_uploaded_date: current.first_uploaded_date,
      gifts_over_opens: current.gifts_over_opens,
      hard_bounce_emails: current.hard_bounce_emails,
      hard_bounce_rate: current.hard_bounce_rate,
      hard_bounces: current.hard_bounces,
      jira_ticket_num: current.jira_ticket_num,
      open_emails: current.open_emails,
      open_rate: current.open_rate,
      opens: current.opens,
      optout_emails: current.optout_emails,
      optout_rate: current.optout_rate,
      optouts: current.optouts,
      pixel_load_emails: current.pixel_load_emails,
      pixel_load_rate: current.pixel_load_rate,
      pixel_loads: current.pixel_loads,
      revenue: current.revenue,
      sends: current.sends,
      soft_bounce_emails: current.soft_bounce_emails,
      soft_bounce_rate: current.soft_bounce_rate,
      soft_bounces: current.soft_bounces,
      spam_emails: current.spam_emails,
      spam_rate: current.spam_rate,
      spams: current.spams,
      spend: current.spend,
      delivery_month: current.delivery_month,
      delivery_year: current.delivery_year,
      send_emails_past_30: current.send_emails_past_30,
      cost_per_donor: current.cost_per_donor,
      updated_dt: current.updated_dt,
    };

    if (isCohort) {
      formatted.cohort = current.cohort;
      formatted.cohort_name = current.cohort_name;
    }

    if (tableName === 'Reac Cohort') {
      formatted.recurring_flag = current.recurring_flag;
    }

    return formatted;
  });
  return myrows;
};

const formatSumBySourceDate = (transactionDt, dateBreakdown) => {
  /**
   * format date based on checkbox selection for breakdown
   * @param {date} transactionDt - transaction_dt from transactions table
   * @param {string} dateBreakdown - breakdownByDay, breakdownByMonth, breakdownByYear from sum by source selection
   * @returns {Array} The formatted date based on breakdown value chosen
   */
  let dateFormat;
  if (dateBreakdown === 'breakdownByDay') {
    dateFormat = 'MM/DD/YYYY';
  }
  if (dateBreakdown === 'breakdownByMonth') {
    dateFormat = 'MM/YYYY';
  }
  if (dateBreakdown === 'breakdownByYear') {
    dateFormat = 'YYYY';
  }
  const formattedDate = moment(transactionDt).format(dateFormat);
  return formattedDate;
};

const createSumBySourceRows = (data, dateBreakdown, recurringFlag, aggregateCount) => {
  /**
   * format rows for the sum by source feature
   * some values are based on checkbox selection
   * @param {Array} graphql data we fetch that includes some code, source, and transaction data.
   * @param {string} dateBreakdown - breakdownByDay, breakdownByMonth, breakdownByYear from sum by source selection
   * @param {boolean} whether we need to check and break out recurring payment data into it's own rows
   * @returns {Array} The formatted rows ready for data grid table
   */
  let current;
  let formattedDate;
  let percentage;
  let prepend;
  let recurrence;
  let id;
  let channel;
  let category;
  const totalAmount = aggregateCount;
  // filter null
  const formattedData = data.filter((ele) => ele !== null);
  // formatting data for table
  const myrows = Object.keys(formattedData).map((source) => {
    id = uuidv4();
    current = formattedData[source];
    // check if we want a breakdown for recurring gifts
    recurrence = current.recurrence;
    if (recurringFlag && recurrence !== 1) {
      prepend = true;
    }
    // format date based on checkbox selection for breakdown
    formattedDate = formatSumBySourceDate(current.transaction_dt, dateBreakdown);
    percentage = (current.amount / totalAmount);
    // format channel and category if value is undefined
    channel = current.channel || 'None';
    category = current.channel_category || 'None';
    return {
      client_id: current.client_id,
      date: formattedDate,
      channel: prepend ? `~Recurring: ${channel}` : channel,
      'channel:category': prepend ? `~Recurring: ${channel}:${category}` : `${channel}:${category}`,
      category: prepend ? `~Recurring: ${category}` : category,
      gifts: 1,
      amount: current.amount,
      '% of total': percentage,
      code: prepend ? `~Recurring: ${current.code}` : current.code,
      id,
    };
  });
  return myrows;
};

/**
 * @param {Array} data - data from api ImportHistoryByClient graphql query
 * @returns {Array} The formatted rows ready for data grid table, consumed by AdvancedSearch
 * note this logic is duplicated in the import history table, fetchBasicImportHistory() does the same thing
 */
const createImportHistoryRows = (data) => {
  const myrows = data[0].map((item) => {
    const current = item;
    const exclusionCount = current.updated_exclusion_count !== null && current.total_req_exclusion_count !== null ? `${current.updated_exclusion_count.toString()}/${current.total_req_exclusion_count.toString()}` : '';
    const exclusionCountNum = current.updated_exclusion_count !== null && current.total_req_exclusion_count !== null ? current.updated_exclusion_count / current.total_req_exclusion_count : 0;
    const status = (typeof current.updated_exclusion_count === 'number' && typeof current.total_req_exclusion_count === 'number') && exclusionCountNum < 1 ? 'failed' : 'success';
    return {
      id: current.s3_key,
      client: current.client,
      createdAt: current.createdAt,
      updatedAt: current.updatedAt,
      error: status === 'failed' && current.error !== 'null' ? current.error : '',
      warning: current.warning !== 'null' ? current.warning : '',
      status,
      exclusionCount,
      timestamp: current.timestamp,
      csv_name: current.csv_name,
    };
  });
  return myrows;
};

const createInsightsNonCohortRows = (data) => createInsightsRows(data, false, null);

const createInsightsCohortRows = (data) => createInsightsRows(data, true, null);

const createInsightsReacCohortRows = (data) => createInsightsRows(data, true, 'Reac Cohort');

export {
  alphabetizeCols,
  createClientRows,
  createCodeRows,
  createSourceRows,
  createSumBySourceRows,
  createTransactionRows,
  createInsightsNonCohortRows,
  createInsightsCohortRows,
  createInsightsReacCohortRows,
  createImportHistoryRows,
};
